import knowhow from './../icons/knowhow.svg';
import about_pic from './../header_pictures/about_01.jpg';
import bygg_pic from './../header_pictures/bygg_01.jpg';
import fukt_pic from './../header_pictures/fukt_01.jpeg';
import torketjenester_01 from './../header_pictures/torketjenester_01.jpeg';
import torketjenester_02 from './../header_pictures/torketjenester_02.jpg';
import fuktkontroll_3 from './../header_pictures/fuktkontroll_3.jpg';
import fuktkontroll_4 from './../header_pictures/fuktkontroll_4.jpg';
import soppsanering_5 from './../header_pictures/soppsanering_5.webp';
import soppsanering_6 from './../header_pictures/soppsanering_6.jpg';
import ozon_7 from './../header_pictures/ozon_7.jpg';
import ozon_8 from './../header_pictures/ozon_8.jpg';
import opprydding_9 from './../header_pictures/opprydding_9.jpg';
import opprydding_10 from './../header_pictures/opprydding_10.jpeg';

const articles = [
    {
        title: 'Fukt og Skadesanering',
        main: false,
        full: [
            ['img', fukt_pic],
            ['header', 'Skadebegrensning etter vann- og flomskader'],
            ['paragraph', 'I Norge er det et økende problem med vannskader og fuktskader. Sopp- og fuktskader ødelegger mer i norske bygg enn brannskader. Årsaken har alltid sammenheng med fukt, for eksempel fukt som følge av lekkasje og kondens. Fukt fører til råte og muggsopp.'],
            ['paragraph', 'Du som huseier må være klar over at vannskade er noe som skjer plutselig, eksempel brudd på rør og at fuktskader er noe som skjer over tid. Sistnevnte dekkes sjelden av forsikring, og kan gi by på store økonomiske og helsemessige utfordringer.'],
            ['header', 'Det er derfor viktig at du utbedrer vann og fuktskader så fort som mulig.'],
            ['paragraph', 'LAUR Gruppen AS er en tømrer-entreprenør med spisskompetanse innen fukt- og tørketeknikk.Vi har spesialisert oss på å sanere og utbedre skader på dette området og har tilgang til metoder og utstyr som sikrer en korrekt behandling av vannskader av alle typer og omfang.Valg av riktige reparasjonsmetode er avgjørende for reparasjonens kvalitet, kostnad, risiko for inneklimaproblem og ikke minst reparasjonstiden.Det er derfor viktig at du utbedrer vann og fuktskader så fort som mulig.'],
            ['header', 'Hva kan vi hjelpe deg med og de viktigste oppgavene som må gjøres...'],
            ['paragraph', 'Vi er klare til å reagere på kort varsel og når vi blir tilkalt til et skadested, er vår første oppgave å vurdere skadeomfanget på skadestedet og hvorvidt det er nødvendig med akutte skadebegrensende tiltak.'],
            ['paragraph', 'Er det nødvendig med tiltak, kan vi raskt iverksette lokalisering av skadeårsak, stoppe ytterligere tilførsel av vann, fjerning av fritt vann, flytte eller tildekke inventar for å forhindre ytterligere skader. Etter utførte førstehjelpstiltak starter prosessene med fjerning av ødelagt løsøre og bygningsdeler. Avfukting / tørking av oppfuktede bygningsdeler, hvor vi benytter moderne tørkeutstyr som er energieffektivt. Vær snill å ikke skru av vifter eller flytt på dem uten at du er i kontakt med din prosjektleder. Om du gjør endringer vil tørkeprosessen kunne ta lengre tid enn nødvendig.'],
            ['paragraph', 'Tett og god kommunikasjon mellom deg og prosjektleder er viktig, spesielt i denne fasen av utbedringen av skaden,Vi garanterer at vi skal bistå deg med det som er mest optimalt med din utfordring enten det er vann eller fuktskade.'],
            ['header', 'Skadebegrensning etter vann- og flomskader omfatter blant annet:'],
            ['list', ['Behandling av forsikringskrav',
                'Fjerning av fritt vann',
                'Flytte innbo',
                'Sikring av innbo',
                'Skadevurdering',
                'Avfukting / Tørking',
                'Riving',
                'Mugg- og soppsanering',
                'Håndverkstjenester',
                'Prosjektledelse',
                'Rensing og luktsanering ved sotskade',]
            ],
            ['paragraph', 'Er man raskt ute og gjør saneringsjobben riktig, kan store verdier spares. Skadebegrensning og sanering ved sotskade handler om sikring av verdier, riving og sanering av berørte flater, vask av bygning og løsøre samt luktsanering.'],
            ['header', 'Vi fokuserer på kostnadseffektive og riktige utbedringer når det gjelder kjemikaliebehandling og fjerning av lukt. Vi har egne programmer og metoder for det meste innen luktsanering og stank fjerning.'],
        ],  
        sidebox: [
            ['Gjennomsiktig samarbeid', 'Når skaden er klar for gjenoppbygging har LAUR Gruppen ansvaret for mengdeberegning, bestilling for riktig type materialer til prosjektet.'],
            ['.', 'Bygningsdelen som er skadet skal da repareres og tilbakeføres tilbake til samme, eller tilnærmet samme stand, slik den var før skaden inntraff.'],
            ['Samarbeidspartnere', 'Vi har egne håndverkere og et nettverk av dyktige elektrikere, rørleggere, malere mm.'],
            ['Kontakt Oss', 'Når uhellet er ute tar du kontakt med oss på telefon 46 91 02 04, eller epost.']
        ],
        link: 'fukt-og-skadesanering',
        ico: fukt_pic,
        tags: [],
        icotext: 'Les mer',
        category: [0,1,0,0]
    },
    {
        title: 'Tørketjenester',
        brief: 'Velg riktig metode og instrument for fuktmåling',
        main: 'fukt-og-skadesanering',
        full: [
            ['img', torketjenester_02],
            ['paragraph', 'Start tørkeprosessen. Mugg begynner å utvikle seg innen 24-48 timer etter en oversvømmelse, så det er utrolig viktig å begynne med tørkeprosessen så raskt som mulig for å begrense vannskader.'],
            ['paragraph', 'LAUR Gruppen og effektiv respons- Dette kan inkludere bruk av vifter, avfuktere eller til og med profesjonelle tørkemaskiner. Samtidig vi også rengjøre overflater og gjenstander grundig for å hindre mugg- og soppskader. Ved å bruke spesialiserte rengjøringsmidler slik at alle spor av mugg, sopp og bakterier blir eliminert.'],
            ['paragraph', 'Våre tørkemetoder reduserer behovet for omfattende riving og gjenoppbygging.'],
        ],  
        sidebox: [
            ['Gjennomsiktig samarbeid', 'Når skaden er klar for gjenoppbygging har LAUR Gruppen ansvaret for mengdeberegning, bestilling for riktig type materialer til prosjektet.'],
            ['.', 'Bygningsdelen som er skadet skal da repareres og tilbakeføres tilbake til samme, eller tilnærmet samme stand, slik den var før skaden inntraff.'],
            ['Samarbeidspartnere', 'Vi har egne håndverkere og et nettverk av dyktige elektrikere, rørleggere, malere mm.'],
            ['Kontakt Oss', 'Når uhellet er ute tar du kontakt med oss på telefon 46 91 02 04, eller epost.']
        ],
        link: 'torketjenester',
        ico: torketjenester_01,
        tags: [],
        icotext: 'Les mer',
        category: [0,1,0,0]
    },
    {
        title: 'Fuktkontroll',
        brief: 'Etter en vannlekkasje er det viktig å tørke opp alt vannet og fjerne fuktighet fra de berørte områdene',
        main: 'fukt-og-skadesanering',
        full: [
            ['img', fuktkontroll_4],
            ['paragraph', 'Måling av fukt er særlig aktuelt ved kontroll av materialer og konstruksjoner på byggeplass, undersøkelser ved fukt- og vannskader og undersøkelser av inneklimaproblemer.'],
            ['paragraph', 'Fukt er en vesentlig bekymringsfaktor, i både nye og gamle bygninger. Fukt kan medføre omfattende skader på bygninger og medføre en betydelig redusert kvalitet på innklimaet.'],
        ],  
        sidebox: [
        ],
        link: 'fuktkontroll',
        ico: fuktkontroll_3,
        tags: [],
        icotext: 'Les mer',
        category: [0,1,0,0]
    },
    {
        title: 'Soppsanering',
        brief: 'Det er viktig å fjerne fukt og sopp før det blir helsefarlig.',
        main: 'fukt-og-skadesanering',
        full: [
            ['img', soppsanering_6],
            ['paragraph', 'Mugg er en type sopp som kan leve både innendørs og utendørs. Muggsopp oppstår og trives spesielt i fuktige omgivelser, og derfor er det viktig å være obs på fuktskader i boligen din. Vi finner den normalt i baderom og kjellere. Alle muggsopparter kan være helseskadelige, da de inneholder allergener. Det er derfor viktig å bli kvitt muggsopp.'],
            ['paragraph', 'Muggsoppsanering er en nødvendighet straks muggsoppen oppdages. Sanering av mugg er prosessen som utføres for å fjerne koloniene av mugg som har bygget seg opp. For at dette skal bli gjort på en trygg måte, utføres arbeidet som oftest av fagfolk. Sanering krever nemlig kompetanse og erfaring, slik at saneringen blir fullstendig og gjenoppbyggingen skjer forsvarlig.'],
            ['paragraph', 'Det kreves profesjonell, kjemisk eller varmebehandlet sanering av hussoppangrep for å fjerne ekte hussopp.'],
            ['paragraph', 'Angrepet treverk må fjernes. I tillegg kjemisk desinfeksjon en sikkerhetssone ved å ta bort friskt tre i området rundt skaden.'],
            ['paragraph', 'Vi anbefaler å kontakte oss snarest mulig for å kartlegge omfanget av skaden dersom den har skjedd.'],

        ],  
        sidebox: [
           
        ],
        link: 'soppsanering',
        ico: soppsanering_5,
        tags: [],
        icotext: 'Les mer',
        category: [0,1,0,0]
    },

    {
        title: 'Ozon og Lukt',
        brief: 'En ozongeneratorer produserer ozon og lages for å gi en effektiv og sikker behandling av lukt og mikroorganismer uten bruk av kjemikalier',
        main: 'fukt-og-skadesanering',
        full: [
            ['img', ozon_8],
            ['header', 'Hvordan virker en ozongenerator?'],
            ['list', 
                ['Luft blir ført inn I et elektronisk utladningsfelt som blir produsert ved høy spenning.',
                'Resultatet av utladningene er en aktiv oksygenblanding som inneholder alle tre formene for oksygen; O, O2 og O3.',
                'Ozon (O3) er svært aktivt og reagerer med organiske luftmolekyler, og dreper bakterier ved å ta livet av den proteinlignende strukturen til mikroorganismene.',
                'Osmogenene (luktproduserende molekyler) blir oksydert til vann og andre ufarlige gasser.',
                ],
            ],
            ['header', 'Ozon fjerner lukt'],
            ['paragraph', 'Ozon er svært aktivt og reagerer med organiske luftmolekyler og dreper bakterier ved å ta livet av den proteinlignende strukturen til mikroorganismene. Osmogenene (luktproduserende molekyler) blir oksydert til vann og andre ufarlige gasser.'],
            ['header', 'Hvor lenge skal ozongeneratoren stå på?'],
            ['paragraph', 'En kraftig ozongenerator bruker kortere tid på å fjerne lukt. Om generatoren bør stå på i en halv time, fem timer eller et døgn er noe man prøver seg frem med i begynnelsen. Størrelsen på rommet og hvor stort luktproblemet er vil natuligvis variere. Ozon er en frisk og distinkt lukt. Selv om ozon bryter seg selv ned raskt, kan noe av denne lukte sitte igjen hvis man ozonerer for lenge, og det tar lengre tid å lufte. Vi anbefaler å ozonere i intervall, det vil si at du ozonerer en økt for deretter å lufte godt.'],
            ['header', 'Ozon dreper bakterier, virus, mugg og sopp'],
            ['paragraph', 'Ozon er svært aktivt og reagerer med organiske luftmolekyler. Ozon dreper bakterier ved å ta livet av den proteinlignende strukturen til mikroorganismene. I de fleste tilfeller blir sluttproduktene bare kullsyre og vann.'],
            ['header', 'Bruksområder'],
            ['list', 
                ['Drepe mugg og sopp i hus på overflatene samt sporer i luften. Skadede materialer må byttes, ikke minst hvis det er skader inni veggene. Ozon virker ikke inni vegger og materialer, men i luften og på alle overflater.',
                'Desinfeksjon av luft, instrumenter osv.',
                'Drepe midd i sengen. Her er luftvolumet så lite (under dynen) at mindre ozongeneratorer kan brukes.',
                'Drepe bakterier i ventilasjonsanlegg, klimaanlegg o.l',
                'Fjerning av allergi- og astmafremkallende mikroorganismer.'
                ],
            ],
            ['paragraph', 'Ozon inngår ofte i luftbehandlingssystem og ved sanering av "syke" hus, kontor, skoler, sykehus, hotell, vann-, avfalls- og avløpsrenseanlegg.'],
            ['header', 'Store rom og lokaler'],
            ['paragraph', 'Ozon bryter seg selv ned i løpet av relativt kort tid, slik at konsentrasjonen vil være størst i nærheten av apparatet. Det er derfor umulig å ozonere for eksempel et helt hus samtidig, uansett hvor stor ozongeneratoren er. I slike tilfeller må generatoren flyttes fra rom til rom. I større, åpne lokaler kreves derfor en kraftig ozongenerator. Ozon er tyngre enn luft, så en høy sentral plassering er fint. Med Y-kobling og slanger kan ozonen fordeles om ønskelig med BLC-serien.'],
            ['header', 'Ozonering med slange'],
            ['paragraph', 'Airmaster BLC-serien har mulighet for å koble til slange. Da kan man føre slangen inn i en klespose for å fjerne røykelukt og andre lukter fra klær og sko. Jaktklær kan behandles på den måten slik at ikke dyr lukter deg så lett. Det er uheldig for ozongeneratoren med fuktig luft. Hvis du har vasket en bil innvendig, kan derfor aggregatet plasseres på utsiden av bilen, og slangen føres inn gjennom en glipe på et vindu. Slangen gir mange muligheter som for eksempel å ozonere vann. Har du en død mus i en vegg, kan du bore et 4 mm hull i veggen og føre inn ozon til lukten har gitt seg. Er det rotter i krypkjelleren kan ozon gjøre det for utrivelig for disse dyrene.'],
            ['header', 'Kommunale boliger'],
            ['paragraph', 'Ozon fjerner lukt i rom. Brukes i rom for rom etter utvasking og tørking.'],
            ['paragraph', 'Til desinfisering i helsesektor'],
            ['paragraph', 'Ozon kan brukes som supplement til vasking for desinfisering av for eksempel:'],
            ['list',
                [
                    'Venterom, og andre rom. Karenstid i infiserte rom blir ikke nødvendig',
                    'Ambulanse, varebiler, biler for hjemmesykepleien osv',
                    'Rullestoler og annet brukerutstyr',
                    'P2-masker, hansker, masker osv. kan raskt desinfiseres i en boks, skap eller lite rom.',
                    'Ventilasjonsanlegg'
                ],
            ],
            ['paragraph', 'Ozongassen kommer til overalt, også i porøse materialer, etterlater ingen rester og tilfører ikke fuktighet. Ozon er en av de mest oksiderende og desinfiserende middel man kjenner til.']
        ],  
        sidebox: [
        ],
        link: 'ozon-og-lukt',
        ico: ozon_7,
        tags: [],
        icotext: 'Les mer',
        category: [0,1,0,0]
    },

    {
        title: 'Opprydding etter skade',
        brief: 'Vi kan hjelpe til med å rydde etter skaden har skjedd.',
        main: 'fukt-og-skadesanering',
        full: [
            ['img', opprydding_10],
            ['paragraph', 'En skade på bolig og innbo kommer alltid ubeleilig. Heldigvis er ikke alle ødeleggelser like omfattende, selv om det kan bli en del arbeid med opprydding og reparasjon.'],
            ['paragraph', 'Tenk deg at et vannrør har sprunget lekk i et etasjeskille. Vannet har rent ned i rommet under, som nå må ryddes, rives og bygges opp igjen'],
            ['paragraph', 'Vi rydder og tømmer små og store boliger. Leiligheter, hus og eiendommer. Vi fjerner alt av løst inventar i boligen så det blir klart for rengjøring. Vi har bærekraftige løsninger for behandling av avfall så du kan være trygg på at det blir riktig håndtert. Vi gjør alltid vårt ytterste for at du skal oppleve god service i alle ledd, fra første telefonsamtale til tilbakelevering av nøkkel.'],
            ['header', 'Hva må du gjøre'],
            ['paragraph', 'Gjennomgå boligen og ta med det som du ønsker å beholde. Er det noe av verdi som du ikke vil ha og som du tenker kan selges så benytt finn.no. Resten lar du ligge. Og du trenger ikke rydde. Vi foretar en fullstendig sortering og bortkjøring av resterende inventar. Møbler og annet som kan ha en annenhåndsverdi forsøkes å gis bort.'],
            ['header', 'Rengjøring'],
            ['paragraph', 'Vi har gode sertifiserte samarbeidspartnere for rengjøring av boliger. Alle virksomheter som tilbyr renholdstjenester skal være godkjent av Arbeidstilsynet. Ved privat kjøp av renholdstjenester er man pliktet til å sjekke renholdsregisteret. Med våre samarbeidspartnere er du sikret en smertefri overlevering av boligen.'],
            ['header', 'Rydding og tømming av dødsbo'],
            ['paragraph', 'Noen har klart å rydde opp etter seg før livets slutt, men de fleste har ikke det. De fleste har skuffer, skap, kjeller, boder og garasjer fylt med ting. Vi har sett det meste, fra svært ryddige boliger til ekstreme tilfeller. Vi har forståelse for at noen har hatt en vanskelig livssituasjon. Å rydde opp i et dødsbo kan være omfattende. Og vi vet hvor vanskelig det kan være å rydde opp i dødsbo til en som står deg nær. Vil du vite mer om hvordan vi kan hjelpe deg, så ta kontakt med oss.'],

        ],  
        sidebox: [
           
        ],
        link: 'opprydding-etter-skade',
        ico: opprydding_9,
        tags: [],
        icotext: 'Les mer',
        category: [0,1,0,0]
    },

    {
        title: 'Bygg og rehabilitering',
        main: false,
        full: [
            ['img', bygg_pic],
            ['header', 'Påbygg, tilbygg og rehabilitering'],
            ['paragraph', 'Vi utvikler og vokser kontinuerlig som et selskap for å tilby bedre løsninger og mer fordelaktige tilbud. Vi bidrar med all vår kompetanse inntil du får det resultatet du ønsker. Vi har gode erfaringer med å optimalisere bokvaliteten i eksisterende boliger – og vi har nært samarbeid med gode rådgivere etter behov.'],
            ['header', 'Laur bygg og rehabilitering utfører det meste innen:'],
            ['header', 'Innendørs:'],
            ['list', 
                ['Endring av planløsning,',
                'Nye overflater',
                'Nytt kjøkken og garderobe',
                'Nye gulv og gulvsliping',
                'Snekkerarbeid',
                'Flislegging',
                'Utskifting av dører og vinduer',
                ],
            ],
            ['header', 'Utendørs: '],
            ['list', 
                ['Utskifting og bytte av vinduer ',
                    'Etablering av takvinduer ',
                    'Tilleggs- /etterisolering',
                    'Skifte kledning ',
                    'Bytte tak',
                    'Maling']
            ],
            ['paragraph', 'Slike prosjekter vi har god erfaring med. Vi kan være en god samarbeidspartner og bidra med verdifulle innspill – og selvsagt utføre vårt håndverk slik at både du og vi kan være stolte av resultatet.'],
            ],  
        sidebox: [
            ['Dyktige arbeidere', 'Opussing og bygging må gi varige resultater i årevis, så vi sørger for å ansette de beste spesialistene som garanterer en god effekt.'],
            ['God kommunikasjon', 'Vi jobber med alle typer prosjekter, vi vet hvor viktig det er å forstå investorens forventninger og behov, og det er derfor vi bryr oss om effektiv kommunikasjon.'],
            ['Attraktive priser', 'Vår prioritet er å inngå rettferdige og fordelaktige kontrakter med våre kunder, hver gang vi leverer høyeste kvalitet til konkurransedyktige priser.'], 
        ],
        link: 'bygg-og-rehabilitering',
        ico: bygg_pic,
        tags: [],
        icotext: 'Les mer',
        category: [1,0,0,0] 
    },
    {
        title: 'Om Oss',
        main: false,
        full: [
            ['img', about_pic],
            ['header', 'Om Oss'],
            ['header', 'LAUR Gruppen og Skadeteknikk ble etablert av medarbeidere som allerede da hadde over 20 års solid erfaring innen bygg og rehabilitering og skadeteknikk.'],
            ['paragraph', 'Våre oppdrag handler om å levere gode tjenester til våre kunder, ofte i en situasjon når noe uønsket har skjedd.'],
            ['paragraph', 'Kombinasjonen av lang erfaring, faglig dyktighet, egne programmer og metoder og vårt felles ønske om å levere tjenester av høy kvalitet, har gjort oss til en naturlig samarbeidspartner for både offentlige og private kunder'],
            ['paragraph', 'LAUR Gruppen utfører et viktig samfunnsoppdrag. Vi sikrer verdier når det oppstår skader på eiendom. I tillegg hjelper vi kunder innen privat- og næringsmarkedet med oppgraderinger og verdiutvikling.'],
            ['paragraph', 'Vi løser dine problemer raskt, effektivt og med gode resultater. Vi har spisskompetanse i et bredt tjenestespekter som sikrer at vi kan håndtere dine utfordringer med god kvalitet til konkurransedyktige betingelser.'],
            ['paragraph', 'Våre kunder er eiendomsforvaltere, privatpersoner samt industri og forsikringsselskaper som setter høye krav til rask respons og forutsigbare riktige leveranser i alle prosjekter knyttet til skadebegrensning, forebygging og vedlikehold.'],
            ['header', 'Hvorfor jobbe med oss?'],
            ['paragraph', 'Ved å velge LAUR Gruppen får du mer enn en totalentreprenør på laget. Du får en partner, dedikert til å realisere din visjon med ekstraordinær fleksibilitet og omfattende ekspertise. Vår tilnærming sikrer en bekymringsfri oppussingsreise med full gjennomsiktighet gjennom daglige oppdateringer, og et stort nettverk av dyktige partnere for å gjøre din opplevelse enklere. Oppdag forskjellen et engasjert team kan gjøre for ditt prosjekt med LAUR Gruppen.'],
            ['header', 'Hvordan vi jobber'],
            ['paragraph', 'Hos LAUR Gruppen begynner vi med å lytte til dine drømmer når vi kommer på gratis befaring. '],
            ['paragraph', 'Fra første samtale til det ferdige prosjektet, gjøres alt med et mål om å skape en stressfri og smidig opplevelse for deg. Med åpen kommunikasjon og regelmessige oppdateringer, sikrer vi en smidig fremdrift og høy kvalitet. Vår tilnærming er enkel: å forstå dine ønsker, planlegge nøye og levere drømmeprosjektet med presisjon.'],
            ['header', 'Sett i gang med ditt prosjekt!'],
            ['paragraph', 'Alle vellykkede prosjekter begynner med en god samtale. Ta kontakt med oss i dag for en gratis og helt uforpliktende befaring, der vi kan møtes og diskutere dine ideer og behov. Vi ser frem til å bli en del av din oppussingsreise']
        ],  
        sidebox: [
            ['Høy kvalitet.','LAUR Gruppen er en god samarbeidspartner som man kan stole på.'], 
            ['Punktlighet', 'Vi leverer tjenestene til avtalt tid og sted.'],
            ['Pålitelighet og transparens', 'Ingen skjulte kostnader eller overraskelser underveis'],
            ['Innovative tjenester', 'Vi setter standarden gjennom bruk av moderne verktøy og prosessautomatisering.'],
            ['Kundestøtte av høykvaltet', 'Vi har respekt for deg som kunde og lytter til dine behov og ønsker']
        ],
        link: 'om-oss',
        ico: about_pic,
        tags: [],
        icotext: 'Les mer',
        category: [0,0,0,0] 
    },
];

export {articles};