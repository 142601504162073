import React, { Component } from "react";

import { Navbar, Nav, NavDropdown} from 'react-bootstrap';
import { getClientData } from "../DataModelEngine";
import { articles } from "./../content/articlesContent";

class NavigationBar extends Component {
    constructor () {
        super();
        this.state = {};
    } 
    

  render (){
    let autoMenu = [];
    articles.map((article) => (
        !article.main && !autoMenu.includes({title: article.title, link: article.link}) && autoMenu.push({title: article.title, link: article.link})
    ))
    let submenu = autoMenu.map((menuItem) => (
        articles.filter((element)=> (element.main === menuItem.link))
    ))
    let menu = submenu.map((submenuItem, index) => submenuItem.length > 0 ? [autoMenu[index]].concat(submenuItem) : submenuItem = (autoMenu[index]) );
    menu.unshift({title: "Hjem", link: ''});
    menu.push({title: "Kontakt oss", link: 'kontakt'});
    console.log(autoMenu);
    console.log(submenu);
    console.log(menu);

    const menuItems = [
        {title: "Hjem", link: ''},
        {title: "Bygg og rehabilitering", link: 'bygg-og-rehabilitering'},
        {title: "Fukt og Skadesanering", link: 'fukt-og-skadesanering'},
        {title: "Om Oss", link: 'om-oss'},        
        {title: "Kontakt oss", link: 'kontakt'}
    ];

    return (
        <Navbar 
            variant="light" 
            expand="lg" 
            id="navigation-menu" 
            style={{color: '#000'}} 
            collapseOnSelect
        >
            <Navbar.Brand 
                href="/">				
                    <div className='nav-logo'>
                        <img 
                            src={getClientData('logotype')} 
                            alt={getClientData('clientCompanyName')} 
                        />
                    </div>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse >
                <Nav 
                    className="ml-auto" 
                    style={{zIndex: '999'}}>
                    
                    {menu.length > 0 && menu.map((item, index)=>(
                    <>
                        {Array.isArray(item) && <NavDropdown title={item[0].title} id="navbarScrollingDropdown">
                            {item.map((submenu, index) => (
                                index !== 0 && <NavDropdown.Item href={submenu.link}>{submenu.title}</NavDropdown.Item>
                            ))}
                            {//<NavDropdown.Divider />
                            }
                        </NavDropdown>}
                        {!Array.isArray(item) && <Nav.Link 
                        name={item.link} 
                        key={index} 
                        href={'/'+item.link}>
                            {item.title}
                        </Nav.Link>} 
                    </>   
                    ))}

                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )}
}

export default NavigationBar;