


const frontPageArticle = 
{
  brief: 'Gulvsliping i Oslo',
  full:  [
    'Leter du etter gulvslipespesialister som skal utføre parkettsliping til en god pris?',
    'Vi kan fjerne riper, sprekker og flekker som har oppstått gjennom årene og gjenopprette den opprinnelige fargen på tregulvet.', 
    'Ved å velge Østgulvsliping AS får du',
    'gulvsliping i rom av alle størrelser',
    'høykvalitets parkettsliping',
    ' miljøvennlige materialer',
    'over 15 års erfaring',
  ],
  link: 'om-oss',
  ico: '',
  tags: ['gulvsliping', 'parkettsliping', 'trappersliping', 'gulvbehandling', 'gulvrenovering', 'sliping av gulv', 'gulvslipere', 'slipe tregulv', 'behandling av gulv', 'behandling av tregulv'],
  icotext: 'Les mer',
  category: [1,0,0]
};

const tilbudContent= 
{
    full: 'Når uhellet er ute tar du kontakt med oss på telefon 46 91 02 04, eller epost.',
    icotext: 'Gratis befaring' 
}



export {frontPageArticle, tilbudContent};
