import React, { Component } from "react";
import './App.css';
import MainWindow from "./MainWindow";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import sitemap from './sitemap.xml';

import { articles } from "./content/articlesContent";


class App extends Component {
  constructor () {
    super();
	  this.state = {
    }

  } 

  componentDidMount()
  {
  }
  render ()
  {

    return (
      <Router>
        <Routes>
          <Route exact path="/kontakt" element={<MainWindow view='kontakt'/>}>
          </Route>
          <Route exact path="/artikler" element={<MainWindow view='knowhow'/>}>
          </Route>
          {articles && articles.map((article, index)=>(
            <Route key={index} exact path={"/"+article.link} element={<MainWindow view='knowhow' article={article.link}/>}>
          </Route>
          ))}
          <Route exact path="/" element={<MainWindow/>}>
          </Route>        
        
          <Route path="*" element={<MainWindow/>} />

        </Routes>
    </Router>

    );
  }
}

export default App;
