/* eslint-disable no-lone-blocks */
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import GalleryContainer from '../gallery/GalleryContainer';
import KnowHowBeam from "./KnowHowBeam";
import KnowHowTags from "./KnowHowTags";
import KnowHowList from "./KnowHowList";

import { articles } from "./../content/articlesContent";

class KnowHowArticle extends Component {
  constructor () {
    super();
	  this.state = {

    }
  } 


          
  render (){
    const article = this.props.article ? this.props.article : {};
    console.log(article.link);
     //articles =  articles.filter((art)=> (art.category[1] === 1 && !art.main));

    const category_articles =  articles.filter((art)=> (art.main === article.link));
    const index = this.props.index ? this.props.index : 0;
    const line = function (articleLine, index){
      if (index !== 0 )
      {

        if (Array.isArray(articleLine) && articleLine[0] === 'header')
          {
            return <h1>{articleLine[1]}</h1>
          }
        if (Array.isArray(articleLine) && articleLine[0] === 'paragraph')
          {
            return <p>{articleLine[1]}</p>
          }
        if (Array.isArray(articleLine) && articleLine[0] === 'img')
          {
            return <div id="know-how-picture-frame">     
              <div id="know-how-picture-centered" style={{backgroundImage: 'url('+(articleLine[1])+')'}}>        
              
              </div>
            </div>
          }
        if (Array.isArray(articleLine) && articleLine[0] === 'list')
          {
            return <ul>{articleLine[1].map((elem, index) => (
              <li>{elem}</li>
            ))}</ul>
          }
      }

    }
    return (
        <>
          {article && 
          <>
          <Row>
          <div id={'#'+article.link}></div>
          <Col xs={12} sm={12} md={12} lg={12} style={{padding: 0}}>
            <div><p id='know-how-header'>
            {line(article.full[0], 3)}
            {line(['header', article.title], 3)}
            </p></div>

            <div>
          </div>
          </Col>
          </Row>
          {category_articles.length > 0 && <KnowHowList articles={category_articles}/>}
          <Row>
          {
            //main content
          }
          <Col xs={12} sm={12} md={9} lg={9}>
            <Col xs={12} sm={12} md={12} lg={{span: 10, offset: 1}}>
                <div>
                  <KnowHowTags tags={article.tags}/>
                </div>
              <div id="know-how-description">
                  <div>
                  {line(article.full[2], 3)}
                  </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={{span: 10, offset: 1}}>
              <div id="know-how-description">
                  <div>
                      {article.full.map((articleLine, index)=>
                        line(articleLine, index)
                      )}
                  </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <KnowHowTags tags={article.tags}/>
            </Col>          
            <Col xs={12} sm={12} md={12} lg={12}>
             {
             //<KnowHowBeam/>
             }
            </Col>
          </Col>
          {
            //sideboxy
          }
          <Col xs={12} sm={12} md={2} lg={2}>
          {article.sidebox.map((sideboxText, index)=> (
            <div id="know-how-sidebox">
              <div id="know-how-sidebox-header">
                <h1>{sideboxText[0]}</h1>
              </div>
              <div>
                <p>{sideboxText[1]}</p>
              </div>
          </div>))}
          </Col>
        </Row>
        </>
          }
        </>
        )
}
}

export default KnowHowArticle;
