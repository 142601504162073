import logotype from './icons/logo.svg';

const getClientData = function (type) {
    const clientData = {
        clientName: "Laur Gruppen AS",
        clientKey: "",
        uid: "LAUR0",
        clientCompanyTaxNumber: "000 000 000 MVA",
        clientCompanyName: "Laur Gruppen AS",
        clientAddress: "",
        clientCity: "",
        clientDomain: "laurgruppen.no",
        clientEmail: "post@laurgruppen.no",
        clientPhone: "+4746910204",
        clientFancyPhone: '46 91 02 04',
        facebook: "",
        instagram: "",
        logotype: logotype,

    }
    return clientData[type];
}

export {getClientData};
